@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&family=Oswald:wght@300;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: white;
}

.active{
  font-weight: 700;
}
.container{
  background-color: black;
  width: 100vw;
  height: 100vh;
  margin: auto;
  top: 0;
  color: white;
  display: grid;
  grid-template-columns: 3% 94% 3%;
  grid-template-rows: 6% 88% 6%;
  overflow: hidden;
}

.banner{
  font-family: 'Roboto', sans-serif;
  font-size: 3.5vh;
  overflow-x: hidden;
  overflow-y: hidden;
}
.banner p{
  padding: 0;
  margin: 0;
  word-spacing: 2vh;
  text-align: justify;
  white-space: nowrap;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.banner span{
  display: none;
}
.horizontal{
  display: flex;
  flex-direction: row;
}
.horizontal p{
    margin: auto;
}
.vertical{
  display: flex;
  flex-direction: column;
  background-color: black;
  padding-left: 1vh;
}
.vertical p{
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  margin: auto;
}

.top{
  grid-column: 1/4;
  grid-row: 1/2;
  background-color: black;
  z-index: 4;
}
.bottom{
  grid-column: 1/4;
  grid-row: 3/4;
  background-color: black;
  z-index: 4;
}
.left{
  grid-column: 1/2;
  grid-row: 1/4;
  z-index: 5;
  transform: rotate(180deg);
}

.right{
  grid-column: 3/4;
  grid-row: 1/4;
  z-index: 5;
}
.other{
  grid-column: 2/3;
  grid-row: 2/3;
  overflow: visible;
}

#top{
  display: flex;
  flex-direction: row;
  -webkit-animation: top-one 25s linear infinite;
          animation: top-one 25s linear infinite;

}
#top:hover{
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
@-webkit-keyframes top-one {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes top-one {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
/*LEFT BANNER ANIMATION*/
#left{
  display: flex;
  flex-direction: column;
  -webkit-animation: left-one 12.5s linear infinite;
          animation: left-one 12.5s linear infinite;
}
#left:hover{
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
@-webkit-keyframes left-one {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(50%);
  }
}
@keyframes left-one {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(50%);
  }
}
/*RIGHT BANNER*/
#right{
  display: flex;
  flex-direction: column;
  -webkit-animation: right-one 13s linear infinite;
          animation: right-one 13s linear infinite;
}
#right:hover{
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
@-webkit-keyframes right-one {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes right-one {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
 /*BOTTOM BANNER*/
 #bottom{
   display: flex;
   flex-direction: row;
   -webkit-animation: bot-one 25s linear infinite;
           animation: bot-one 25s linear infinite;
 }
 #bottom:hover{
   -webkit-animation-play-state: paused;
           animation-play-state: paused;
 }
 @-webkit-keyframes bot-one {
   0% {
     transform: translateX(0%);
   }
   100% {
     transform: translateX(-50%);
   }
 }
 @keyframes bot-one {
   0% {
     transform: translateX(0%);
   }
   100% {
     transform: translateX(-50%);
   }
 }

 .desk{
   display: flex;
 }
 .mob{
   display: none;
 }
 .mob-active{
   display: none;
 }

 /* +++++++++ MOBILE LAYOUT ++++++++++++++*/

@media only screen and (max-width: 800px){
  .desk{
    display: none;
  }
  .mob{
    display: flex;
    flex-direction: row;
    font-size: 7vw;
    font-family: 'Roboto', sans-serif;
  }
  .about-active{
    display: flex;
    flex-direction: row;
    background-color: black;
    width: 100vw;
    white-space: nowrap;
    z-index: 10;
    -webkit-animation: selected-ab 2s linear infinite;
            animation: selected-ab 2s linear infinite
  }
  .about-active::after{
    content: "about about about about about about about about about about about";
    font-family: 'Roboto', sans-serif;
    font-size: 7vw;
    padding: 0;
    margin: 0;
  }
  .work-active{
    display: flex;
    flex-direction: row;
    background-color: black;
    white-space: nowrap;
    z-index: 10;
    -webkit-animation: selected-wk 2s linear infinite;
            animation: selected-wk 2s linear infinite;
  }
  .work-active::after{
    content: "work work work work work work work work work work work work work";
    font-family: 'Roboto', sans-serif;
    font-size: 7vw;
    padding: 0;
    margin: 0;
  }
  @-webkit-keyframes selected-ab {
    0% {
      transform: translateX(-19.8%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes selected-ab {
    0% {
      transform: translateX(-19.8%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @-webkit-keyframes selected-wk {
    0% {
      transform: translateX(-17%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes selected-wk {
    0% {
      transform: translateX(-17%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .container{
    padding-top: 3%;
    display: flex;
    flex-direction: column;
  }
  .bottom{
    padding-bottom: 3%;
  }
  .vertical{
    display: flex;
    flex-direction: row;
    background-color: none;
    z-index: 5;
  }
  .vertical p{
    -webkit-writing-mode: horizontal-tb;
        -ms-writing-mode: lr-tb;
            writing-mode: horizontal-tb;
    margin: auto;
  }
  .left{
    transform: none;
  }
}

@media only screen and (min-width: 800px) and (max-width:1024px) and (orientation: landscape){
  .container{
    grid-template-columns: 4% 92% 4%;
  }
}

#gridbox{
  height: 87.808vh;
  display: grid; /*grid*/
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  background-color: white;
}
.imgbox{
  width: 31.5vw;
  height: 100%;
}
.card{
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}
.card:hover .overlay{
  width: 100%;
}
.multiply{
  background-color: #be1717;
  mix-blend-mode: multiply;
}
.text{
  text-transform: uppercase;
  text-align: left;
  font-size: 5vh;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 150%;
  width: 100%;
  margin-left: -100%;
  word-wrap: break-word;
}

.card:hover .text{
  width: 100%;
  margin-left: 1%;
}

.expanded{
  display: flex;
  flex-direction: row;
  position: relative;
  background: black;
  height: 100%;
  width: 100%;

}
.imgdiv{
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: auto;
  max-width: 50%;
  -ms-overflow-style: none;
  scrollbar-width: thin;
}
.imgdiv::-webkit-scrollbar {
  display: block !important;
  width: 0.5vw !important;
}
.imgdiv::-webkit-scrollbar-track {
  background: #bebebe;
}
.imgdiv::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 2px;
}
.singleimg{
  align-self: center;
}
.catalog{
 height: auto;
 width: 100%;
}
.imgdiv img{
  height: auto;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  padding-bottom: 2%;
}
.imgdiv img:last-child{
  padding-bottom: 0%;
}
.information{
  padding-left: 2%;
  padding-right: 3%;
  padding-top: 5%;
  width: 100%;
  height: 100%;
  align-items: center;
  align-self: center;
}

.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.back{
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5vw;
  align-self: center;
  cursor: pointer;
}
.back::before{
  content: "<";
}
.title{
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2vw;
}
.description{
  padding-top: 3%;
  text-align: justify;
  font-size: 1.2vw;
}

@media only screen and (max-width: 800px ){
  #gridbox{
    height: 100vh;
    position: absolute;
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .card{
    width: 50%;
     flex-grow: 1;
     justify-content: space-between;
     box-sizing: border-box;
     background-size: cover;
     background-repeat: no-repeat;
     background-position: center;
  }
  .imgbox{
    width: 50vw;
    height: 100%;

  }
  .overlay{
    width: 100%;
  }
  .text{
    margin-left: 0%;
    font-size: 6vw;
    width: 100%;
    word-wrap: break-word;
    text-align: center;
  }

  /* +++++EXPANDED ++++++++*/
  .expanded{
    position: absolute;
    display: flex;
    flex-direction: column;
    background: black;
    height: 100%;
    width: 100%;
    padding-top: 2%;

  }
  .imgdiv{
    min-height: auto;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    background-color: black;
  }
  .imgdiv::-webkit-scrollbar {
    height: 1.5%;
  }
  .imgdiv img{
    width: 100%;
    height: auto;
    display: flex;
    flex-shrink: 0;
    padding-right: 2%;
    padding-bottom: 0%;
  }
  .imgdiv img:last-child{
    padding-right: 0%
  }
  .information{
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0%;
    background-color: black;
    height: 100%;
  }
  .header{
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    flex-direction: row;
    justify-content: space-between;
    background-color: black
  }
  .title{
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 8vw;
    width: 70%;
  }
  .back{
    font-size: 6vw;
  }
  .description{
    padding: 10%;
    text-align: justify;
    font-size: 4vw;
    background-color: black;
  }
}

.wrapper{
  position: relative;
  margin: auto;
  display: flex; /*flex*/
  align-content: center;
  justify-content: center;
}
.about{
  width: 100%;
  height: 100%;
  padding: 5%;
  display: flex;
  flex-direction: row;
  z-index: 2;
}
.about-img{
  width: 100%;
  height: auto;
  margin-right:2%;
}
.about p{
  font-family: 'Roboto', sans-serif;
  font-size: 3vh;
  margin: 0;
  text-align: justify;
}
.logo {
  position: absolute;
  display: flex;
  align-self: center;
  width: 45%;
  height: auto;
  opacity: 0.1;
  z-index: 1;
}
@media only screen and (max-width: 800px ){
  .wrapper{
    position: absolute;
  }
  .about{
    width: 80%;
    height: 100%;
    padding: 10%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    background-color: black;
  }
  .about-img{
    width: 100vw;
    height: auto;
  }
  .about p{
    font-family: 'Roboto', sans-serif;
    font-size: 4vw;
    padding-top: 10%;
    margin: 0;
    text-align: justify;
  }
}

