@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
a{
  text-decoration: none;
  color: white;
}

.active{
  font-weight: 700;
}
.container{
  background-color: black;
  width: 100vw;
  height: 100vh;
  margin: auto;
  top: 0;
  color: white;
  display: grid;
  grid-template-columns: 3% 94% 3%;
  grid-template-rows: 6% 88% 6%;
  overflow: hidden;
}

.banner{
  font-family: 'Roboto', sans-serif;
  font-size: 3.5vh;
  overflow-x: hidden;
  overflow-y: hidden;
}
.banner p{
  padding: 0;
  margin: 0;
  word-spacing: 2vh;
  text-align: justify;
  white-space: nowrap;
  user-select: none;
}
.banner span{
  display: none;
}
.horizontal{
  display: flex;
  flex-direction: row;
}
.horizontal p{
    margin: auto;
}
.vertical{
  display: flex;
  flex-direction: column;
  background-color: black;
  padding-left: 1vh;
}
.vertical p{
  writing-mode: vertical-lr;
  margin: auto;
}

.top{
  grid-column: 1/4;
  grid-row: 1/2;
  background-color: black;
  z-index: 4;
}
.bottom{
  grid-column: 1/4;
  grid-row: 3/4;
  background-color: black;
  z-index: 4;
}
.left{
  grid-column: 1/2;
  grid-row: 1/4;
  z-index: 5;
  transform: rotate(180deg);
}

.right{
  grid-column: 3/4;
  grid-row: 1/4;
  z-index: 5;
}
.other{
  grid-column: 2/3;
  grid-row: 2/3;
  overflow: visible;
}

#top{
  display: flex;
  flex-direction: row;
  animation: top-one 25s linear infinite;

}
#top:hover{
  animation-play-state: paused;
}
@keyframes top-one {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
/*LEFT BANNER ANIMATION*/
#left{
  display: flex;
  flex-direction: column;
  animation: left-one 12.5s linear infinite;
}
#left:hover{
  animation-play-state: paused;
}
@keyframes left-one {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(50%);
  }
}
/*RIGHT BANNER*/
#right{
  display: flex;
  flex-direction: column;
  animation: right-one 13s linear infinite;
}
#right:hover{
  animation-play-state: paused;
}
@keyframes right-one {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
 /*BOTTOM BANNER*/
 #bottom{
   display: flex;
   flex-direction: row;
   animation: bot-one 25s linear infinite;
 }
 #bottom:hover{
   animation-play-state: paused;
 }
 @keyframes bot-one {
   0% {
     transform: translateX(0%);
   }
   100% {
     transform: translateX(-50%);
   }
 }

 .desk{
   display: flex;
 }
 .mob{
   display: none;
 }
 .mob-active{
   display: none;
 }

 /* +++++++++ MOBILE LAYOUT ++++++++++++++*/

@media only screen and (max-width: 800px){
  .desk{
    display: none;
  }
  .mob{
    display: flex;
    flex-direction: row;
    font-size: 7vw;
    font-family: 'Roboto', sans-serif;
  }
  .about-active{
    display: flex;
    flex-direction: row;
    background-color: black;
    width: 100vw;
    white-space: nowrap;
    z-index: 10;
    animation: selected-ab 2s linear infinite
  }
  .about-active::after{
    content: "about about about about about about about about about about about";
    font-family: 'Roboto', sans-serif;
    font-size: 7vw;
    padding: 0;
    margin: 0;
  }
  .work-active{
    display: flex;
    flex-direction: row;
    background-color: black;
    white-space: nowrap;
    z-index: 10;
    animation: selected-wk 2s linear infinite;
  }
  .work-active::after{
    content: "work work work work work work work work work work work work work";
    font-family: 'Roboto', sans-serif;
    font-size: 7vw;
    padding: 0;
    margin: 0;
  }
  @keyframes selected-ab {
    0% {
      transform: translateX(-19.8%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes selected-wk {
    0% {
      transform: translateX(-17%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .container{
    padding-top: 3%;
    display: flex;
    flex-direction: column;
  }
  .bottom{
    padding-bottom: 3%;
  }
  .vertical{
    display: flex;
    flex-direction: row;
    background-color: none;
    z-index: 5;
  }
  .vertical p{
    writing-mode: horizontal-tb;
    margin: auto;
  }
  .left{
    transform: none;
  }
}

@media only screen and (min-width: 800px) and (max-width:1024px) and (orientation: landscape){
  .container{
    grid-template-columns: 4% 92% 4%;
  }
}
