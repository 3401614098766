@import url('https://fonts.googleapis.com/css2?family=Lato&family=Oswald:wght@300;400;600&display=swap');

#gridbox{
  height: 87.808vh;
  display: grid; /*grid*/
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  background-color: white;
}
.imgbox{
  width: 31.5vw;
  height: 100%;
}
.card{
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}
.card:hover .overlay{
  width: 100%;
}
.multiply{
  background-color: #be1717;
  mix-blend-mode: multiply;
}
.text{
  text-transform: uppercase;
  text-align: left;
  font-size: 5vh;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 150%;
  width: 100%;
  margin-left: -100%;
  word-wrap: break-word;
}

.card:hover .text{
  width: 100%;
  margin-left: 1%;
}

.expanded{
  display: flex;
  flex-direction: row;
  position: relative;
  background: black;
  height: 100%;
  width: 100%;

}
.imgdiv{
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: auto;
  max-width: 50%;
  -ms-overflow-style: none;
  scrollbar-width: thin;
}
.imgdiv::-webkit-scrollbar {
  display: block !important;
  width: 0.5vw !important;
}
.imgdiv::-webkit-scrollbar-track {
  background: #bebebe;
}
.imgdiv::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 2px;
}
.singleimg{
  align-self: center;
}
.catalog{
 height: auto;
 width: 100%;
}
.imgdiv img{
  height: auto;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  padding-bottom: 2%;
}
.imgdiv img:last-child{
  padding-bottom: 0%;
}
.information{
  padding-left: 2%;
  padding-right: 3%;
  padding-top: 5%;
  width: 100%;
  height: 100%;
  align-items: center;
  align-self: center;
}

.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.back{
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5vw;
  align-self: center;
  cursor: pointer;
}
.back::before{
  content: "<";
}
.title{
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2vw;
}
.description{
  padding-top: 3%;
  text-align: justify;
  font-size: 1.2vw;
}

@media only screen and (max-width: 800px ){
  #gridbox{
    height: 100vh;
    position: absolute;
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .card{
    width: 50%;
     flex-grow: 1;
     justify-content: space-between;
     box-sizing: border-box;
     background-size: cover;
     background-repeat: no-repeat;
     background-position: center;
  }
  .imgbox{
    width: 50vw;
    height: 100%;

  }
  .overlay{
    width: 100%;
  }
  .text{
    margin-left: 0%;
    font-size: 6vw;
    width: 100%;
    word-wrap: break-word;
    text-align: center;
  }

  /* +++++EXPANDED ++++++++*/
  .expanded{
    position: absolute;
    display: flex;
    flex-direction: column;
    background: black;
    height: 100%;
    width: 100%;
    padding-top: 2%;

  }
  .imgdiv{
    min-height: auto;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    background-color: black;
  }
  .imgdiv::-webkit-scrollbar {
    height: 1.5%;
  }
  .imgdiv img{
    width: 100%;
    height: auto;
    display: flex;
    flex-shrink: 0;
    padding-right: 2%;
    padding-bottom: 0%;
  }
  .imgdiv img:last-child{
    padding-right: 0%
  }
  .information{
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0%;
    background-color: black;
    height: 100%;
  }
  .header{
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    flex-direction: row;
    justify-content: space-between;
    background-color: black
  }
  .title{
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 8vw;
    width: 70%;
  }
  .back{
    font-size: 6vw;
  }
  .description{
    padding: 10%;
    text-align: justify;
    font-size: 4vw;
    background-color: black;
  }
}
