.wrapper{
  position: relative;
  margin: auto;
  display: flex; /*flex*/
  align-content: center;
  justify-content: center;
}
.about{
  width: 100%;
  height: 100%;
  padding: 5%;
  display: flex;
  flex-direction: row;
  z-index: 2;
}
.about-img{
  width: 100%;
  height: auto;
  margin-right:2%;
}
.about p{
  font-family: 'Roboto', sans-serif;
  font-size: 3vh;
  margin: 0;
  text-align: justify;
}
.logo {
  position: absolute;
  display: flex;
  align-self: center;
  width: 45%;
  height: auto;
  opacity: 0.1;
  z-index: 1;
}
@media only screen and (max-width: 800px ){
  .wrapper{
    position: absolute;
  }
  .about{
    width: 80%;
    height: 100%;
    padding: 10%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    background-color: black;
  }
  .about-img{
    width: 100vw;
    height: auto;
  }
  .about p{
    font-family: 'Roboto', sans-serif;
    font-size: 4vw;
    padding-top: 10%;
    margin: 0;
    text-align: justify;
  }
}
